import React, { useState } from 'react';
//import react pro sidebar components
import 'assets/styles/pages/sidebar.scss';
import { Link, navigate } from 'gatsby';
import treeicon from 'assets/images/admin/tree-icon.png';
import breadcumbarrow from 'assets/images/admin/breadcumb-arrow-left.png';

import { useForm } from "react-hook-form";

import AdminService from 'services/admin/admin.service';
import { Modal, Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import PlantationListDetails from 'services/plantation/plantation.service';
import imageCompression from 'browser-image-compression';
import { compressOptions } from 'assets/data/imageCompress';
import closesmall from 'assets/images/icons/close-small-icon.svg';
// import Initiativeviewspecies from 'components/admin/initiative-view';

export default function Addprojectpage(props) {

  const adminApi = new AdminService();
  const ds = new PlantationListDetails();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false)
  const [filesList, setFilesList] = useState([]);
  const [signedList, setSignedList] = useState([]);
  const [fileError, setFileError] = useState([false]);
  const [pathImage, setPathImage] = useState([]);
  const [typeError, setTypeError] = useState(false);
  const [sizeError, setSizeError] = useState(false);



  const saveImg = async (data) => {
    setLoading(true)
    const plantationResponse = await ds.imagesSignedUrl({ signedUrlDetails: signedList });
    if (plantationResponse !== undefined) {
      if (plantationResponse.status === 200) {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.error("Something went wrong");
    }
    await imageUploadCall(plantationResponse.data.signedUrlDetails, data);
  }
  const imageUploadCall = async (data, formValues) => {
    setLoading(true)
    let pathImages = []
    const resultsData = await data.map((item, index) => {

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/octet-stream");
      myHeaders.append("x-goog-acl", "public-read");

      let requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: filesList[index],
        redirect: 'follow'
      };
      setLoading(true)
      return fetch(item.signedUrl, requestOptions).then(result => {
        var obj = {};
        obj["pictureUrl"] = data[index].objectPath;
        pathImages.push(obj);
      })
    })

    return Promise.all(resultsData).then(() => {
      finalCall(pathImages, formValues)
    }).catch(e => {
      toast.error("Something went wrong")
      setLoading(false);
    })
    // setTimeout(function () {
    //   setLoading(true);
    // }, 10000)
  }

  const finalCall = (imagesList, formValues) => {
    setLoading(true);
    let formData = {
      "name": formValues.projectname,
      "description": formValues.projectDescription,
      "phoneNumber": formValues.phone,
      "email": formValues.email,
      "projectStartDate": new Date(formValues.date),
      "isLeafProject": false,
      "parentProjectId": 0,
      "pictureDetails": imagesList,
      "targetCount": formValues.targetCount,
      "latitude": formValues.latitude,
      "longitude": formValues.longitude
    }
    adminApi.addProject(formData)
      .then(data => {
        if (data.status === 200) {
          pathImage.length = 0;
          setSignedList([]);
          toast.success("Project added successfully");
          setTimeout(function () {
            setLoading(false);
            navigate("/admin/projectstatus");
          }, 3000)
        } else {
          setLoading(false);
          pathImage.length = 0;
          setSignedList([]);
          toast.error("Something went wrong");
        }
      })
      .catch(error => {
        setLoading(false)
        if (error.response) {
          pathImage.length = 0;
          setSignedList([]);
          if (error.response.status === 401) {
            toast.error("Authentication Failed");
            navigate("/admin")
          } else {
            toast.error(error.response.data.message);
          }
        }
      }
      );
  }
  const fileLoad = async (e) => {
    let testFile = e.target.files[0]
    if (e.target.files.length !== 0) {
      let fileExtension = testFile.type.split("/").pop();
      const compressedFile = await imageCompression(testFile, compressOptions);

      if (fileExtension !== "png" && fileExtension !== "jpg" && fileExtension !== "jpeg" && fileExtension !== "gif") {
        setTypeError(true)
      } else if (compressedFile.size > 2e+6) {
        setSizeError(true)
        setTypeError(false)
      } else {
        setTypeError(false);
        setSizeError(false);
        setLoading(true)

        const name = e.target.files[0].name;
        const lastDot = name.lastIndexOf('.');

        const fileName = name.substring(0, lastDot);
        const file = e.target.files[0];
        const filetype = e.target.files[0].type;
        let fileExtension = file.type.split("/").pop();
        let imgData = {
          "fileName": fileName,
          "fileExtension": fileExtension,
          "fileType": filetype
        }
        const filesData = [
          ...filesList,
          compressedFile,
        ]
        const signedData = [
          ...signedList,
          imgData,
        ]
        setFilesList(filesData)
        setSignedList(signedData)
        setFileError(false)
        setLoading(false)
      }
    }
  }

  const onSubmit = async (data) => {
    if (signedList.length === 0) {
      setFileError(true)
    }
    if (typeError === false && sizeError === false && fileError === false) {
      await saveImg(data);
    }
  }
  const imageRemove = (e) => {
    let myArray;
    let infoArray;
    myArray = filesList.filter(function (obj) {
      return obj.name !== e.currentTarget.id;
    });
    setFilesList(myArray)
    infoArray = signedList.filter(function (obj) {
      //  const fileVal = (obj.fileName).concat(obj.fileExtension)
      const lastDot = e.currentTarget.id.lastIndexOf('.');
      const fileName = e.currentTarget.id.substring(0, lastDot);
      return obj.fileName !== fileName;
    });
    setSignedList(infoArray)
  }
  return (
    <>
      <div className='sidebar-content-wrapper'>
        <div className='admin-breadcumb-item'>
          <span>
            <Link className='' to='/admin/dashboardview/'><img src={treeicon} alt='treeicon' /></Link>
          </span>

          <span>
            <img src={breadcumbarrow} alt='arrowicon' />
          </span>

          <h3> Add new Project </h3>
        </div>
        <div className='white-iitiative-wrapper'>
          <div className=''>
            <h3 className='sub-heading-title'>ADD NEW PROJECT</h3>
          </div>

          <div className='register-plantation'>
            <div className='container'>
              <div className='row'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='fc-wrapper'>
                        <label htmlFor='name' className='form-label'>
                          Project Name <i className="hastric-color">*</i>
                        </label>
                        <input
                          type='text'
                          name='projectname'
                          className='form-control '
                          id='projectname'
                          defaultValue={props.projectname}
                          {...register("projectname", {
                            required: "Project Name is required"
                          })}
                          placeholder=''
                        />
                        {errors.projectname && (
                          <span className="form-error">
                            {errors.projectname.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='fc-wrapper'>
                        <label htmlFor='date' className='form-label'>
                          Project Start Date  <i className="hastric-color">*</i>
                        </label>
                        <input type='date' name='date' className='form-control ' id='date' placeholder='' {...register("date", {
                          required: "Project Start Date is required",
                        })} />
                        {errors.date && (
                          <span className="form-error">
                            {errors.date.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className='col-md-12'>
                      <div className='fc-wrapper'>
                        <label htmlFor='name' className='form-label'>
                          Project Description  <i className="hastric-color">*</i>
                        </label>
                        <textarea
                          type='textarea'
                          name='projectDescription'
                          className='form-control '
                          id='projectDescription'
                          placeholder='' {...register("projectDescription", {
                            required: "Project Description is required"
                          })} />
                        {errors.projectDescription && (
                          <span className="form-error">
                            {errors.projectDescription.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='fc-wrapper'>
                        <label htmlFor='latitude' className='form-label'>
                          Latitude  <i className="hastric-color">*</i>
                        </label>
                        <input type='text' name='latitude'
                          {...register("latitude", {
                            required: "Latitude is required"
                          })}
                          className='form-control ' id='latitude' placeholder='' />
                        {errors.latitude && (
                          <span className="form-error">
                            {errors.latitude.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='fc-wrapper'>
                        <label htmlFor='name' className='form-label'>
                          Longitude  <i className="hastric-color">*</i>
                        </label>
                        <input type='text' name='longitude'
                          {...register("longitude", {
                            required: "Longitude is required"
                          })}
                          className='form-control ' id='longitude' placeholder='' />
                        {errors.longitude && (
                          <span className="form-error">
                            {errors.longitude.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className='col-md-12 mg-top30'>
                      <h3 className='sub-heading-title'> ADD IMAGES </h3>

                      <div className='dis-flex cent-item-mob add-more-img-posi'>
                        {filesList && filesList.map(data => {
                          return (<div className='img_wrp'>
                            <span className='small-close small-close-images hand-pointer close' >
                              <div id={data.name} onClick={imageRemove}> <img src={closesmall} className='img-fluid' alt='Close small' />{' '}</div>
                            </span>
                            <img src={URL.createObjectURL(data)} className='img-fluid' alt='Add image List' width="140px" height="70px" />
                          </div>)

                        })}

                        <div className='border-items margin-left-11'>
                          <span className='plus-icon-green btn-file'>
                            <i className='icon icon-plus-lg plus-symbol-custom-icon'></i>
                            <input type="file" onChange={fileLoad} accept="image/png, image/gif, image/jpeg" />Add more
                          </span>
                          {/* <p><input type="file" onChange={fileLoad} /> Add more</p> */}
                        </div>

                      </div>
                      <div> <br />
                        {sizeError === true ?
                          <span className="form-error">
                            Please upload a file smaller than 2 MB
                          </span>
                          : null}
                        {typeError === true ?
                          <span className="form-error">
                            File does not support. You must use .png or .jpg
                          </span>
                          : null}
                        {fileError === true ? <span className="form-error">Add at least one image</span> : null}
                      </div>
                    </div>

                    <h3 className='sub-heading-title'> CONTACT </h3>

                    <div className='col-md-6'>
                      <div className='fc-wrapper'>
                        <label htmlFor='name' className='form-label'>
                          Phone Number  <i className="hastric-color">*</i>
                        </label>
                        <input name='phone' className='form-control ' id='phone'
                          type="number"
                          {...register("phone", {
                            required: "Phone Number is required",
                            pattern: {
                              value: /^(\+91[\s]?)?[0]?(91)?[6789]\d{9}$/,
                              message: "Please enter a valid Mobile Number",
                            },
                          })}
                        />
                        {errors.phone && (
                          <span className="form-error">
                            {errors.phone.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='fc-wrapper'>
                        <label htmlFor='name' className='form-label'>
                          Email ID  <i className="hastric-color">*</i>
                        </label>
                        <input
                          type='email'
                          name='email'
                          className='form-control '
                          id='email'
                          placeholder='Enter your email address'
                          {...register("email", {
                            required: "Email Id is required",
                            pattern: {
                              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Please enter a valid email",
                            },
                          })}
                        />
                        {errors.email && (
                          <span className="form-error">
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='fc-wrapper'>
                        <label htmlFor='name' className='form-label'>
                          Target Count  <i className="hastric-color">*</i>
                        </label>
                        <input
                          type='number'
                          name='targetCount'
                          className='form-control '
                          id='targetCount'
                          placeholder='Enter your Target Count'
                          {...register("targetCount", {
                            required: "Target Count is required"
                          })}
                        />
                        {errors.targetCount && (
                          <span className="form-error">
                            {errors.targetCount.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='fc-wrapper'>
                        <label htmlFor='planttype' className='form-label'>
                          Status <i className="hastric-color">*</i>
                        </label>
                        <select name='planttype' id='planttype' className='form-control' {...register("planttype")}>
                          <option value='2'>Active</option>
                          <option value='1'> Inactive </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='text-right'>
                    <Link to='/admin/projectstatus' className='btn btn-cancel mg-right10 margin-btm-10'>
                      Cancel
                    </Link>

                    {/* <Link to='/admin/projectstatus' className='btn btn-viewmore save-width'>
                      Save
                    </Link> */}
                    <button className='btn btn-viewmore save-width'>
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </>
  );
}
