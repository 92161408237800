import React from 'react';

import AdminLayout from '../../adminlayout/index';

import SEO from 'components/seo/index';
// import Side from 'layout/sidebaritem';

import Addprojectpage from 'components/admin/addprojectpage';

const Addproject = () => {
  return (
    <>
      <SEO title='Forests By Heartfulness admin' />
      <AdminLayout>
      <div>
        <Addprojectpage />
      </div>
      </AdminLayout>
    </>
  );
};

export default Addproject;
